<template>
    <tr
        class="border-b border-slate-100 bg-white hover:bg-slate-50"
        :class="{ 'cursor-pointer': hasResource || hasEmitter }"
        @click="navigateToResource"
    >
        <slot />
    </tr>
</template>

<script>
import { getProperty } from "@/utils/object";
import { redirectWithParamsTo } from "@/router";

export default {
    props: {
        resource: {
            type: String,
            default: null,
        },
        resourceParams: {
            type: Array,
            default: () => [],
        },
        emit: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        hasResource() {
            return (
                this.resource !== null &&
                this.getProperty(this.item, "id", false)
            );
        },
        hasEmitter() {
            return this.emit !== false;
        },
        hasParams() {
            return this.resourceParams.length > 0;
        },
        paramObject() {
            let params = {};
            this.resourceParams.forEach((param) => {
                params[param] = this.getProperty(this.item, param, null);
            });

            return params;
        },
    },
    methods: {
        getProperty,
        navigateToResource() {
            if (this.emit) {
                this.$emit("emit", this.item);
                return;
            }

            if (!this.hasResource) {
                return;
            }

            redirectWithParamsTo({
                name: this.resource,
                params: this.hasParams
                    ? this.paramObject
                    : { id: this.item.id },
            });
        },
    },
};
</script>
