<template>
    <TwoCol>
        <div slot="content">
            <SlideOver
                :active="showHeaders"
                :gutter="false"
                @close="showHeaders = false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("threats.details.email_headers") }}
                </h2>
                <div
                    slot="content"
                    class="h-full overflow-auto bg-slate-50 p-4"
                >
                    <div
                        class="p-7.5 flex flex-col space-y-2.5 break-all text-sm text-slate-700"
                    >
                        <div
                            v-for="header in threat.headers"
                            :key="header.id"
                            class="space-x-2"
                        >
                            <span class="underline">{{ header.name }}</span
                            ><span>{{ header.value }}</span>
                        </div>
                    </div>
                </div>
            </SlideOver>
            <Prompt
                :active="showTransitionPrompt"
                @close="showTransitionPrompt = false"
                :gutter="false"
                ><h3 slot="header">{{ $t("threats.report.heading") }}</h3>
                <div slot="content">
                    <FormFieldSet class="p-4">
                        <FormFieldOutline>
                            <FormToggle
                                v-if="transitionTo"
                                v-model="notifyReporters"
                                name="notify_reporters"
                                help="forms.help.notify_reporters"
                            />
                            <FormSelect
                                v-else
                                name="status"
                                v-model="transitionTo"
                            >
                                <option
                                    v-for="status in threatStatusesOptions"
                                    :key="status"
                                    :value="status"
                                >
                                    {{ $t(`threats.status.${status}`) }}
                                </option>
                            </FormSelect>
                        </FormFieldOutline>
                        <FormButton
                            @click.native="transitionThreatStatus"
                            :variant="transitionButtonVariant"
                            :loading="transitionLoading"
                            >{{ transitionLabel }}</FormButton
                        >
                    </FormFieldSet>
                </div>
            </Prompt>
            <Prompt
                :active="showBrowserIsolationPrompt"
                @close="showBrowserIsolationPrompt = false"
                :gutter="false"
                ><h3 slot="header">
                    {{ $t("threats.browser_isolation.heading") }}
                </h3>
                <div slot="content">
                    <LinkPreview v-if="currentLink" :link="currentLink" />
                </div>
            </Prompt>
            <Heading
                :title="$t('threats.report.heading').toString()"
                :text="
                    $t('threats.report.text', {
                        date: createdAt,
                    }).toString()
                "
                :loading="loading"
            />
            <div class="card">
                <Alert :error="error" />
                <div
                    class="grid grid-cols-6 gap-y-4 p-4 sm:grid-cols-12 lg:gap-y-8"
                >
                    <div class="col-span-12 md:col-span-3">
                        <h2
                            class="mb-1 flex justify-start align-middle font-bold text-slate-800"
                        >
                            {{ $t("threats.analysis.preview.heading") }}
                        </h2>
                        <p class="text-sm leading-6 text-slate-600">
                            {{ $t("threats.analysis.preview.text") }}
                        </p>
                    </div>
                    <div class="col-span-12 md:col-span-9">
                        <div
                            class="h-100 flex flex-1 flex-col overflow-hidden rounded-xl border bg-slate-50"
                        >
                            <div
                                class="flex flex-row space-x-2 border-b px-3 py-3"
                            >
                                <div
                                    class="size-2.5 rounded-full bg-red-400"
                                ></div>
                                <div
                                    class="size-2.5 rounded-full bg-yellow-300"
                                ></div>
                                <div
                                    class="size-2.5 rounded-full bg-green-400"
                                ></div>
                            </div>
                            <div class="flex flex-col bg-white p-3">
                                <div class="flex flex-col">
                                    <div
                                        class="mb-1.5 flex flex-row items-center justify-between space-x-2"
                                    >
                                        <span class="font-bold">{{
                                            fromName || fromEmail
                                        }}</span>
                                        <span class="text-sm text-slate-400">{{
                                            receivedAt
                                        }}</span>
                                    </div>
                                    <span class="font-bold text-slate-600">{{
                                        subject
                                    }}</span>
                                </div>
                            </div>
                            <div v-if="image" class="relative h-full border-t">
                                <div
                                    class="relative w-full overflow-hidden"
                                    :class="previewExpanded ? 'h-full' : 'h-96'"
                                >
                                    <img
                                        class="relative h-auto w-full"
                                        :src="image"
                                        alt="Email preview"
                                    />
                                </div>
                                <div
                                    class="pointer-events-none absolute inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-white pb-8 pt-32"
                                >
                                    <FormButton
                                        class="pointer-events-auto relative"
                                        @click.native="
                                            previewExpanded = !previewExpanded
                                        "
                                        :variant="ButtonVariants.SECONDARY"
                                        >{{
                                            previewExpanded
                                                ? $t("threats.report.show_less")
                                                : $t("threats.report.show_more")
                                        }}</FormButton
                                    >
                                </div>
                            </div>
                            <div
                                v-else
                                class="flex h-96 flex-col items-center justify-center border-t p-4"
                            >
                                <LoadingSpinner v-if="previewLoading" />
                                <MissingPreviewPlaceholder v-else />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="grid grid-cols-6 gap-y-4 border-t p-4 sm:grid-cols-12 lg:gap-y-8"
                >
                    <div class="col-span-12 md:col-span-3">
                        <h2
                            class="mb-1 flex justify-start align-middle font-bold text-slate-800"
                        >
                            {{ $t("threats.analysis.links.heading") }}
                        </h2>
                        <p class="text-sm leading-6 text-slate-600">
                            {{ $t("threats.analysis.links.text") }}
                        </p>
                    </div>
                    <div class="col-span-12 md:col-span-9">
                        <LinksTable
                            :loading="loading"
                            :links="links"
                            @open="openBrowserIsolationPrompt"
                        />
                    </div>
                </div>
                <div
                    class="grid grid-cols-6 gap-y-4 border-t p-4 sm:grid-cols-12 lg:gap-y-8"
                >
                    <div class="col-span-12 md:col-span-3">
                        <h2
                            class="mb-1 flex justify-start align-middle font-bold text-slate-800"
                        >
                            {{ $t("threats.analysis.attachments.heading") }}
                        </h2>
                        <p class="text-sm leading-6 text-slate-600">
                            {{ $t("threats.analysis.attachments.text") }}
                        </p>
                    </div>
                    <div class="col-span-12 md:col-span-9">
                        <div
                            class="overflow-hidden rounded-xl border border-slate-200"
                        >
                            <div
                                class="flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 px-4 py-3"
                            >
                                <div
                                    class="text-sm font-medium leading-6 text-slate-900"
                                >
                                    {{
                                        $t("threats.analysis.attachments.files")
                                    }}
                                </div>
                            </div>
                            <ContentLoading :loading="loading">
                                <dl
                                    v-if="attachments.length"
                                    class="-my-3 divide-y divide-slate-100 px-4 py-3 text-sm leading-6"
                                >
                                    <div
                                        v-for="(
                                            attachment, index
                                        ) in attachments"
                                        :key="attachment.file_name + index"
                                        class="flex items-center justify-between gap-x-4 py-3"
                                    >
                                        <dt
                                            class="text-slate-500"
                                            v-text="attachment.file_name"
                                        ></dt>
                                    </div>
                                </dl>
                                <div
                                    v-else
                                    class="py-6 text-center text-xs text-slate-400"
                                >
                                    {{
                                        $t("threats.analysis.attachments.empty")
                                    }}
                                </div>
                            </ContentLoading>
                        </div>
                    </div>
                </div>
                <div
                    class="grid grid-cols-6 gap-y-4 border-t p-4 sm:grid-cols-12 lg:gap-y-8"
                >
                    <div class="col-span-12 md:col-span-3">
                        <h2
                            class="mb-1 flex justify-start align-middle font-bold text-slate-800"
                        >
                            {{ $t("threats.analysis.indicators.heading") }}
                        </h2>
                        <p class="text-sm leading-6 text-slate-600">
                            {{ $t("threats.analysis.indicators.text") }}
                        </p>
                    </div>
                    <div class="col-span-12 md:col-span-9">
                        <ThreadIndicators
                            :loading="loading"
                            :analysis="analysis"
                        />
                    </div>
                </div>
                <div
                    class="grid grid-cols-6 gap-y-4 border-t p-4 sm:grid-cols-12 lg:gap-y-8"
                >
                    <div class="col-span-12 md:col-span-3">
                        <h2
                            class="mb-1 flex justify-start align-middle font-bold text-slate-800"
                        >
                            {{ $t("threats.analysis.sender.heading") }}
                        </h2>
                        <p class="text-sm leading-6 text-slate-600">
                            {{ $t("threats.analysis.sender.text") }}
                        </p>
                    </div>
                    <div class="col-span-12 min-h-96 md:col-span-9">
                        <ContentLoading :loading="loading">
                            <div v-if="hasAnalysis">
                                <EmailProviderPreview :analysis="analysis" />
                                <DnsRecordsPreview :mx_records="mx_records" />
                            </div>
                            <MissingAnalysisPlaceholder v-else />
                        </ContentLoading>
                    </div>
                </div>
            </div>
        </div>
        <div slot="sidebar">
            <Heading
                :title="$t('threats.details.heading').toString()"
                :text="$t('threats.details.text').toString()"
            />
            <div class="mb-4 flex flex-col rounded-xl bg-white shadow-sm">
                <ContentLoading :loading="loading">
                    <div
                        class="flex flex-col divide-y divide-slate-100 border-b border-slate-100"
                    >
                        <div class="flex items-center px-4 py-2.5">
                            <span class="text-sm font-medium text-slate-500">{{
                                $t("threats.details.status")
                            }}</span>
                            <div class="flex flex-grow justify-end">
                                <StatusBadge :status="status" />
                            </div>
                        </div>
                        <div class="flex items-center px-4 py-2.5">
                            <span class="text-sm font-medium text-slate-500">{{
                                $t("threats.details.created_at")
                            }}</span>
                            <div
                                class="flex flex-grow justify-end truncate text-sm font-medium leading-6 text-slate-900"
                            >
                                {{ date || "N/A" }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="isEditable"
                        class="flex flex-col space-y-2 border-b p-4"
                    >
                        <FormButton
                            @click.native="openTransitionPrompt('spam')"
                            :variant="ButtonVariants.SECONDARY"
                            >{{
                                $t("threats.details.mark_as_spam")
                            }}</FormButton
                        >
                        <FormButton
                            @click.native="openTransitionPrompt('legitimate')"
                            :variant="ButtonVariants.SECONDARY"
                            >{{
                                $t("threats.details.mark_as_legitimate")
                            }}</FormButton
                        >
                        <FormButton
                            @click.native="openTransitionPrompt('fraudulent')"
                            :variant="ButtonVariants.WARNING"
                            >{{
                                $t("threats.details.mark_as_fraudulent")
                            }}</FormButton
                        >
                    </div>
                </ContentLoading>
                <div
                    class="flex flex-row space-x-2 rounded-b-xl bg-slate-50 p-4"
                >
                    <FormButton
                        class="flex-grow"
                        @click.native="showHeaders = true"
                        :variant="ButtonVariants.SECONDARY"
                        >{{ $t("threats.details.show_headers") }}</FormButton
                    >
                    <DropdownMenu :items="items" @choose="handleSelectItem">
                        <FormButton :variant="ButtonVariants.SECONDARY">
                            ...
                        </FormButton>
                    </DropdownMenu>
                </div>
            </div>
            <div class="card mb-4 flex flex-col">
                <div
                    class="flex flex-row items-center justify-between overflow-hidden border-b p-4"
                >
                    <div class="flex flex-col">
                        <h3
                            class="mb-0 flex justify-start align-middle font-bold text-slate-800"
                        >
                            {{ $t("threats.report.reported_by") }}
                        </h3>
                    </div>
                </div>
                <ContentLoading :loading="loading">
                    <ul>
                        <ReporterRow
                            v-for="employee in reports"
                            :key="employee.id"
                            :employee="employee"
                        />
                    </ul>
                </ContentLoading>
            </div>
            <div class="card flex flex-col">
                <div
                    class="flex flex-row items-center justify-between overflow-hidden border-b p-4"
                >
                    <div class="flex flex-col">
                        <h3
                            class="mb-0 flex justify-start align-middle font-bold text-slate-800"
                        >
                            {{ $t("threats.activity.heading") }}
                        </h3>
                    </div>
                </div>
                <div class="p-4">
                    <ContentLoading :loading="loading">
                        <ul v-if="hasLogs" role="list" class="space-y-6">
                            <ThreatLogItem
                                v-for="log in logs"
                                :key="log.id"
                                :log="log"
                            />
                        </ul>
                        <div
                            v-else
                            class="flex flex-col items-center justify-center py-6"
                        >
                            <Icon
                                :icon="IconEnums.INTEGRATIONS"
                                class="size-8 text-slate-300"
                            />
                        </div>
                    </ContentLoading>
                    <CommentForm
                        class="mt-4"
                        :loading="commentLoading"
                        @comment="handleComment"
                    />
                </div>
            </div>
        </div>
    </TwoCol>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { ThreatStoreNamespacedTypes } from "@/store/modules/threat";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";
import { formatDate, formatDateTime } from "@/utils/date";
import { redirectTo } from "@/router";

import TwoCol from "@/components/layout/TwoCol";
import SlideOver from "@/components/ui/SlideOver";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Prompt from "@/components/ui/Prompt";
import ContentLoading from "@/components/ui/ContentLoading";
import FormToggle from "@/components/forms/fields/FormToggle";
import DropdownMenu from "@/components/ui/DropdownMenu";
import Alert from "@/components/ui/Alert";
import EmailProviderPreview from "@/components/display/threat/EmailProviderPreview";
import DnsRecordsPreview from "@/components/display/threat/DnsRecordsPreview";
import LoadingSpinner from "@/components/ui/LoadingSpinner";
import ReporterRow from "@/components/display/threat/ReporterRow";
import MissingAnalysisPlaceholder from "@/components/display/threat/MissingAnalysisPlaceholder";
import MissingPreviewPlaceholder from "@/components/display/threat/MissingPreviewPlaceholder";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import ThreatLogItem from "@/components/display/threat/ThreatLogItem";
import CommentForm from "@/components/forms/CommentForm";
import ThreadIndicators from "@/components/display/threat/ThreatIndicators";
import FormSelect from "@/components/forms/fields/FormSelect";
import Bus from "@/bus";
import Heading from "@/components/layout/Heading";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import LinksTable from "@/components/display/threat/LinksTable";
import LinkPreview from "@/components/display/threat/LinkPreview";
import StatusBadge from "@/components/display/threat/StatusBadge";

export default {
    name: "ThreatSingle",
    components: {
        StatusBadge,
        LinkPreview,
        LinksTable,
        FormFieldSet,
        FormFieldOutline,
        Heading,
        FormSelect,
        ThreadIndicators,
        CommentForm,
        ThreatLogItem,
        MissingPreviewPlaceholder,
        MissingAnalysisPlaceholder,
        ReporterRow,
        LoadingSpinner,
        DnsRecordsPreview,
        EmailProviderPreview,
        Alert,
        DropdownMenu,
        FormToggle,
        ContentLoading,
        FormButton,
        SlideOver,
        TwoCol,
        Icon,
        Prompt,
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        showBrowserIsolationPrompt: false,
        currentLink: null,
        showTransitionPrompt: false,
        transitionTo: null,
        transitionLoading: false,
        notifyReporters: false,
        showHeaders: false,
        downloadLoading: false,
        previewLoading: true,
        previewExpanded: false,
        commentLoading: false,
    }),
    watch: {
        showTransitionPrompt(val) {
            if (!val) {
                this.transitionTo = null;
            }
        },
    },
    computed: {
        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * Threat management is enabled
         * @type {Boolean}
         */
        threatManagementIsEnabled() {
            return getProperty(
                this.workspace,
                "threat_management_settings.is_enabled",
                false
            );
        },

        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * The threat id from the route params.
         * @type {String}
         */
        threatId() {
            return getProperty(this.$route.params, "id");
        },

        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                ThreatStoreNamespacedTypes.getters.THREAT_LOADING
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                ThreatStoreNamespacedTypes.getters.THREAT_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        threat() {
            return this.$store.getters[
                ThreatStoreNamespacedTypes.getters.THREAT
            ]();
        },

        /**
         * @type {Boolean}
         */
        isUnhandled() {
            return getProperty(this.threat, "status") === "unhandled";
        },

        /**
         * @type {Boolean}
         */
        isEditable() {
            return (
                this.isUnhandled &&
                this.isManager &&
                this.threatManagementIsEnabled
            );
        },

        /**
         * @type {Object}
         */
        threatPreview() {
            return this.$store.getters[
                ThreatStoreNamespacedTypes.getters.THREAT_PREVIEW
            ]();
        },

        /**
         * @type {String}
         */
        image() {
            return getProperty(this.threatPreview, "url");
        },

        /**
         * @type {String}
         */
        receivedAt() {
            return formatDateTime(getProperty(this.threat, "received_at"));
        },

        /**
         * @type {String}
         */
        createdAt() {
            return formatDateTime(getProperty(this.threat, "created_at"));
        },

        /**
         * @type {String}
         */
        status() {
            return getProperty(this.threat, "status");
        },

        /**
         * @type {string|null}
         */
        date() {
            let date = getProperty(this.threat, "created_at");

            if (date) {
                return formatDate(date);
            }

            return null;
        },

        /**
         * @type {String}
         */
        fromName() {
            return getProperty(this.threat, "from_name");
        },

        /**
         * @type {String}
         */
        fromEmail() {
            return getProperty(this.threat, "from_email", "N/A");
        },

        /**
         * @type {String}
         */
        subject() {
            return getProperty(this.threat, "subject", "N/A");
        },

        /**
         * @type {Array}
         */
        links() {
            return getProperty(this.threat, "links", []);
        },

        /**
         * @type {Array}
         */
        attachments() {
            return getProperty(this.threat, "attachments", []);
        },

        /**
         * @type {Array}
         */
        reports() {
            return getProperty(this.threat, "reports", []);
        },

        /**
         * @type {Array}
         */
        items() {
            let items = [
                {
                    id: "download",
                    icon: IconEnums.DOWNLOAD,
                    label: this.$t("threats.details.download_html"),
                },
                {
                    id: "delete",
                    icon: IconEnums.TRASH,
                    label: this.$t("threats.details.delete"),
                },
            ];

            if (!this.isEditable) {
                items.unshift({
                    id: "transition_status",
                    icon: IconEnums.REFRESH,
                    label: this.$t("threats.details.change_status"),
                });
            }

            return items;
        },

        /**
         * @type {Array}
         */
        logs() {
            return getProperty(this.threat, "logs", []);
        },

        /**
         * @type {boolean}
         */
        hasLogs() {
            return this.logs.length > 0;
        },

        /**
         * @type {Object}
         */
        analysis() {
            return getProperty(this.threat, "analysis", {
                sender_mx_records: [],
            });
        },

        /**
         * @type {boolean}
         */
        hasAnalysis() {
            return !!this.analysis;
        },

        /**
         * @type {Array}
         */
        mx_records() {
            return getProperty(this.analysis, "sender_mx_records", []);
        },

        /**
         * @type {String}
         */
        transitionLabel() {
            if (this.transitionTo === "fraudulent") {
                return this.$t("threats.details.mark_as_fraudulent");
            }

            if (this.transitionTo === "legitimate") {
                return this.$t("threats.details.mark_as_legitimate");
            }

            if (this.transitionTo === "spam") {
                return this.$t("threats.details.mark_as_spam");
            }

            return this.$t("threats.details.change_status");
        },

        /**
         * @type {String}
         */
        transitionButtonVariant() {
            return this.transitionTo === "fraudulent"
                ? ButtonVariants.WARNING
                : ButtonVariants.PRIMARY;
        },

        /**
         * @type {Array}
         */
        threatStatusesOptions() {
            return ["fraudulent", "legitimate", "spam"].filter(
                (status) => status !== this.threat.status
            );
        },
    },
    methods: {
        /**
         * Get threat preview image.
         * @return {void}
         */
        getPreview() {
            this.previewLoading = true;
            this.$store
                .dispatch(
                    ThreatStoreNamespacedTypes.actions.GET_THREAT_PREVIEW,
                    this.threatId
                )
                .then(() => {
                    this.previewLoading = false;
                });
        },

        /**
         * Open link isolation prompt.
         * @return {void}
         */
        openBrowserIsolationPrompt(link) {
            this.currentLink = link;
            this.showBrowserIsolationPrompt = true;
        },

        /**
         * Show the transition prompt.
         * @return {void}
         */
        openTransitionPrompt(type) {
            this.transitionTo = type;
            this.showTransitionPrompt = true;
        },

        /**
         * Transition the threat status.
         * @return {void}
         */
        transitionThreatStatus() {
            if (!this.transitionTo) {
                Bus.$emit("flash-message", {
                    text: this.$t("threats.details.choose_status"),
                    type: "error",
                });
                return;
            }

            this.transitionLoading = true;

            this.$store
                .dispatch(
                    ThreatStoreNamespacedTypes.actions.TRANSITION_THREAT_STATUS,
                    {
                        id: this.threatId,
                        status: this.transitionTo,
                        notify: this.notifyReporters,
                    }
                )
                .then(() => {
                    this.$store.commit(
                        WorkspaceStoreNamespacedTypes.mutations
                            .SUBTRACT_UNHANDLED_THREATS_COUNT
                    );

                    this.transitionLoading = false;
                    this.showTransitionPrompt = false;
                });
        },

        /**
         * Handle the select item event from the dropdown.
         * @return {void}
         */
        handleSelectItem(item) {
            switch (item.id) {
                case "download":
                    this.handleDownload();
                    break;
                case "delete":
                    this.handleDelete();
                    break;
                case "transition_status":
                    this.openTransitionPrompt(null);
                    break;
            }
        },

        /**
         * Download the threat as HTML.
         * @return {void}
         */
        handleDownload() {
            this.downloadLoading = true;
            this.$store
                .dispatch(
                    ThreatStoreNamespacedTypes.actions.DOWNLOAD_HTML,
                    this.threatId
                )
                .then((response) => {
                    this.downloadLoading = false;

                    const url = getProperty(response, "data.url");
                    if (url) {
                        window.location.href = url;
                    }
                });
        },

        /**
         * Delete the threat.
         * @return {void}
         */
        handleDelete() {
            this.$store
                .dispatch(
                    ThreatStoreNamespacedTypes.actions.DELETE_THREAT,
                    this.threatId
                )
                .then(() => {
                    redirectTo("threat.index");
                });
        },

        /**
         * Handle the comment event.
         * @param {String} comment
         * @return {void}
         */
        handleComment(comment) {
            this.commentLoading = true;
            this.$store
                .dispatch(ThreatStoreNamespacedTypes.actions.SAVE_COMMENT, {
                    id: this.threatId,
                    comment: comment,
                })
                .then(() => {
                    this.commentLoading = false;
                });
        },
    },
    beforeDestroy() {
        this.$store.dispatch(ThreatStoreNamespacedTypes.actions.CLEAR_THREAT);
    },
    mounted() {
        this.$store
            .dispatch(
                ThreatStoreNamespacedTypes.actions.GET_THREAT,
                this.threatId
            )
            .then(() => {
                this.getPreview();
            });
    },
};
</script>
