<template>
    <OneCol>
        <div v-if="threatManagementIsEnabled">
            <Heading
                :title="$t('threats.heading').toString()"
                :text="$t('threats.text').toString()"
                :loading="loading"
            >
                <FormButton
                    @click.native="exportToCsv"
                    :variant="ButtonVariants.SECONDARY"
                    :icon="IconEnums.DOWNLOAD"
                    :loading="exportLoading"
                >
                    Download CSV
                </FormButton>
                <FormSelect v-model="status" id="status">
                    <option value="all">
                        {{ $t("threats.status.all") }}
                    </option>
                    <option value="unhandled">
                        {{ $t("threats.status.unhandled") }}
                    </option>
                    <option value="legitimate">
                        {{ $t("threats.status.legitimate") }}
                    </option>
                    <option value="fraudulent">
                        {{ $t("threats.status.fraudulent") }}
                    </option>
                    <option value="spam">
                        {{ $t("threats.status.spam") }}
                    </option>
                </FormSelect>
            </Heading>
            <ThreatTable
                :rows="rows"
                :meta="meta"
                :loading="loading"
                :error="error"
                @refresh="handleRefresh"
                class="card"
            />
        </div>
        <div v-else class="flex flex-col items-center justify-center">
            <div
                class="card flex w-full max-w-4xl flex-col items-center justify-between md:flex-row"
            >
                <div
                    class="space-y-7.5 order-2 flex flex-1 flex-col items-start justify-center p-10 md:order-1"
                >
                    <div
                        class="mb-6 flex size-12 items-center justify-center rounded-full bg-rose-500"
                    >
                        <svg
                            class="mx-auto size-8 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
                            />
                        </svg>
                    </div>
                    <h2
                        class="mb-2 text-base font-semibold leading-6 text-slate-900"
                    >
                        {{ $t("threats.disabled.heading") }}
                    </h2>
                    <p class="mb-4 text-slate-500">
                        {{ $t("threats.disabled.text") }}
                    </p>
                    <FormButton
                        :variant="ButtonVariants.SECONDARY"
                        :to="{ name: 'settings.threats' }"
                    >
                        {{ $t("threats.disabled.button") }}
                    </FormButton>
                </div>
                <div
                    class="order-1 hidden px-12 py-4 md:order-2 md:flex md:w-1/2"
                >
                    <svg
                        class="w-full"
                        viewBox="0 0 178 138"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_d_499_322852)">
                            <rect
                                x="12"
                                y="6"
                                width="154"
                                height="40"
                                rx="8"
                                class="fill-white"
                                fill="currentColor"
                                shape-rendering="crispEdges"
                            />
                            <rect
                                x="20"
                                y="14"
                                width="24"
                                height="24"
                                rx="4"
                                class="fill-slate-200"
                                fill="currentColor"
                            />
                            <g clip-path="url(#clip0_499_322852)">
                                <path
                                    d="M32 32C32.3978 32 32.7794 31.8419 33.0607 31.5606C33.342 31.2793 33.5 30.8978 33.5 30.5H30.5C30.5 30.8978 30.658 31.2793 30.9393 31.5606C31.2206 31.8419 31.6022 32 32 32ZM32 21.4385L31.4023 21.5592C30.7242 21.6974 30.1147 22.0656 29.677 22.6015C29.2392 23.1373 29.0001 23.808 29 24.5C29 24.971 28.8995 26.1477 28.6557 27.3065C28.5357 27.8817 28.3738 28.481 28.1585 29H35.8415C35.6262 28.481 35.465 27.8825 35.3442 27.3065C35.1005 26.1477 35 24.971 35 24.5C34.9998 23.8082 34.7605 23.1376 34.3228 22.6019C33.885 22.0662 33.2757 21.6981 32.5977 21.56L32 21.4377V21.4385ZM36.665 29C36.8323 29.3352 37.0258 29.6007 37.25 29.75H26.75C26.9742 29.6007 27.1677 29.3352 27.335 29C28.01 27.65 28.25 25.16 28.25 24.5C28.25 22.685 29.54 21.17 31.2538 20.8242C31.2433 20.72 31.2548 20.6146 31.2875 20.5151C31.3203 20.4155 31.3735 20.3239 31.4438 20.2462C31.5141 20.1685 31.6 20.1063 31.6957 20.0638C31.7915 20.0213 31.8952 19.9993 32 19.9993C32.1048 19.9993 32.2085 20.0213 32.3043 20.0638C32.4 20.1063 32.4859 20.1685 32.5562 20.2462C32.6265 20.3239 32.6797 20.4155 32.7125 20.5151C32.7452 20.6146 32.7567 20.72 32.7462 20.8242C33.594 20.9967 34.356 21.4568 34.9034 22.1266C35.4508 22.7965 35.7499 23.6349 35.75 24.5C35.75 25.16 35.99 27.65 36.665 29Z"
                                    class="fill-slate-600"
                                    fill="currentColor"
                                />
                            </g>
                            <rect
                                x="52"
                                y="17"
                                width="60"
                                height="6"
                                rx="3"
                                class="fill-slate-200"
                                fill="currentColor"
                            />
                            <rect
                                x="52"
                                y="29"
                                width="106"
                                height="6"
                                rx="3"
                                class="fill-slate-200"
                                fill="currentColor"
                            />
                            <rect
                                x="12.5"
                                y="6.5"
                                width="153"
                                height="39"
                                rx="7.5"
                                class="stroke-slate-200"
                                stroke="currentColor"
                                shape-rendering="crispEdges"
                            />
                        </g>
                        <rect
                            x="12.5"
                            y="52.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            class="fill-white"
                            fill="currentColor"
                        />
                        <rect
                            x="20"
                            y="60"
                            width="24"
                            height="24"
                            rx="4"
                            class="fill-slate-100"
                            fill="currentColor"
                        />
                        <g clip-path="url(#clip1_499_322852)">
                            <path
                                d="M32 78C32.3978 78 32.7794 77.8419 33.0607 77.5606C33.342 77.2793 33.5 76.8978 33.5 76.5H30.5C30.5 76.8978 30.658 77.2793 30.9393 77.5606C31.2206 77.8419 31.6022 78 32 78ZM32 67.4385L31.4023 67.5592C30.7242 67.6974 30.1147 68.0656 29.677 68.6015C29.2392 69.1373 29.0001 69.808 29 70.5C29 70.971 28.8995 72.1477 28.6557 73.3065C28.5357 73.8817 28.3738 74.481 28.1585 75H35.8415C35.6262 74.481 35.465 73.8825 35.3442 73.3065C35.1005 72.1477 35 70.971 35 70.5C34.9998 69.8082 34.7605 69.1376 34.3228 68.6019C33.885 68.0662 33.2757 67.6981 32.5977 67.56L32 67.4377V67.4385ZM36.665 75C36.8323 75.3352 37.0258 75.6007 37.25 75.75H26.75C26.9742 75.6007 27.1677 75.3352 27.335 75C28.01 73.65 28.25 71.16 28.25 70.5C28.25 68.685 29.54 67.17 31.2538 66.8242C31.2433 66.72 31.2548 66.6146 31.2875 66.5151C31.3203 66.4155 31.3735 66.3239 31.4438 66.2462C31.5141 66.1685 31.6 66.1063 31.6957 66.0638C31.7915 66.0213 31.8952 65.9993 32 65.9993C32.1048 65.9993 32.2085 66.0213 32.3043 66.0638C32.4 66.1063 32.4859 66.1685 32.5562 66.2462C32.6265 66.3239 32.6797 66.4155 32.7125 66.5151C32.7452 66.6146 32.7567 66.72 32.7462 66.8242C33.594 66.9967 34.356 67.4568 34.9034 68.1266C35.4508 68.7965 35.7499 69.6349 35.75 70.5C35.75 71.16 35.99 73.65 36.665 75Z"
                                class="fill-slate-500"
                                fill="currentColor"
                            />
                        </g>
                        <rect
                            x="52"
                            y="63"
                            width="60"
                            height="6"
                            rx="3"
                            class="fill-slate-200"
                            fill="currentColor"
                        />
                        <rect
                            x="52"
                            y="75"
                            width="106"
                            height="6"
                            rx="3"
                            class="fill-slate-200"
                            fill="currentColor"
                        />
                        <rect
                            x="12.5"
                            y="52.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            class="stroke-slate-200"
                            stroke="currentColor"
                        />
                        <rect
                            x="12.5"
                            y="98.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            class="fill-white"
                            fill="currentColor"
                        />
                        <rect
                            x="20"
                            y="106"
                            width="24"
                            height="24"
                            rx="4"
                            class="fill-slate-50"
                        />
                        <g clip-path="url(#clip2_499_322852)">
                            <path
                                d="M32 124C32.3978 124 32.7794 123.842 33.0607 123.561C33.342 123.279 33.5 122.898 33.5 122.5H30.5C30.5 122.898 30.658 123.279 30.9393 123.561C31.2206 123.842 31.6022 124 32 124ZM32 113.439L31.4023 113.559C30.7242 113.697 30.1147 114.066 29.677 114.602C29.2392 115.137 29.0001 115.808 29 116.5C29 116.971 28.8995 118.148 28.6557 119.307C28.5357 119.882 28.3738 120.481 28.1585 121H35.8415C35.6262 120.481 35.465 119.883 35.3442 119.307C35.1005 118.148 35 116.971 35 116.5C34.9998 115.808 34.7605 115.138 34.3228 114.602C33.885 114.066 33.2757 113.698 32.5977 113.56L32 113.438V113.439ZM36.665 121C36.8323 121.335 37.0258 121.601 37.25 121.75H26.75C26.9742 121.601 27.1677 121.335 27.335 121C28.01 119.65 28.25 117.16 28.25 116.5C28.25 114.685 29.54 113.17 31.2538 112.824C31.2433 112.72 31.2548 112.615 31.2875 112.515C31.3203 112.416 31.3735 112.324 31.4438 112.246C31.5141 112.169 31.6 112.106 31.6957 112.064C31.7915 112.021 31.8952 111.999 32 111.999C32.1048 111.999 32.2085 112.021 32.3043 112.064C32.4 112.106 32.4859 112.169 32.5562 112.246C32.6265 112.324 32.6797 112.416 32.7125 112.515C32.7452 112.615 32.7567 112.72 32.7462 112.824C33.594 112.997 34.356 113.457 34.9034 114.127C35.4508 114.797 35.7499 115.635 35.75 116.5C35.75 117.16 35.99 119.65 36.665 121Z"
                                class="fill-slate-300"
                                fill="currentColor"
                            />
                        </g>
                        <rect
                            x="52"
                            y="109"
                            width="60"
                            height="6"
                            rx="3"
                            class="fill-slate-100"
                        />
                        <rect
                            x="52"
                            y="121"
                            width="106"
                            height="6"
                            rx="3"
                            class="fill-slate-100"
                        />
                        <rect
                            x="12.5"
                            y="98.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            class="stroke-slate-100"
                            stroke="currentColor"
                        />
                        <defs>
                            <filter
                                id="filter0_d_499_322852"
                                x="0"
                                y="0"
                                width="178"
                                height="64"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dy="6" />
                                <feGaussianBlur stdDeviation="6" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_499_322852"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_499_322852"
                                    result="shape"
                                />
                            </filter>
                            <clipPath id="clip0_499_322852">
                                <rect
                                    width="12"
                                    height="12"
                                    class="fill-white"
                                    fill="currentColor"
                                    transform="translate(26 20)"
                                />
                            </clipPath>
                            <clipPath id="clip1_499_322852">
                                <rect
                                    width="12"
                                    height="12"
                                    class="fill-white"
                                    fill="currentColor"
                                    transform="translate(26 66)"
                                />
                            </clipPath>
                            <clipPath id="clip2_499_322852">
                                <rect
                                    width="12"
                                    height="12"
                                    class="fill-white"
                                    fill="currentColor"
                                    transform="translate(26 112)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    </OneCol>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { ThreatStoreNamespacedTypes } from "@/store/modules/threat";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";

import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import ThreatTable from "@/components/display/threat/ThreatTable";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";

export default {
    name: "ThreatIndex",
    components: {
        FormButton,
        FormSelect,
        Heading,
        OneCol,
        ThreatTable,
    },
    computed: {
        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * Threat management is enabled
         * @type {Boolean}
         */
        threatManagementIsEnabled() {
            return getProperty(
                this.workspace,
                "threat_management_settings.is_enabled",
                false
            );
        },

        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                ThreatStoreNamespacedTypes.getters.THREAT_LOADING
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                ThreatStoreNamespacedTypes.getters.THREAT_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        threats() {
            return this.$store.getters[
                ThreatStoreNamespacedTypes.getters.THREATS
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.threats, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.threats, "meta", null);
        },
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        exportLoading: false,
        status: "unhandled",
    }),
    watch: {
        status() {
            this.handleRefresh();
        },
    },
    methods: {
        handleRefresh(payload = {}) {
            const data = {
                status: this.status,
                ...payload,
            };

            this.$store.dispatch(
                ThreatStoreNamespacedTypes.actions.GET_THREATS,
                data
            );
        },
        exportToCsv() {
            this.exportLoading = true;
            this.$store
                .dispatch(ThreatStoreNamespacedTypes.actions.EXPORT_THREAT_LIST)
                .then((response) => {
                    this.exportLoading = false;
                    const url = getProperty(response.data, "url");
                    if (url) {
                        window.location.href = url;
                    }
                });
        },
    },
    mounted() {
        if (this.threatManagementIsEnabled) {
            this.handleRefresh();
        }
    },
};
</script>
