/**
 * A store threat.
 *
 * @typedef {{ threatLoading: boolean, threatError: ErrorOrObject, threats: Object, threat: Object, preview: Object }} ThreatStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const ThreatStoreTypes = {
    getters: {
        THREAT_LOADING: "threatLoading",
        THREAT_ERROR: "threatError",
        THREATS: "threats",
        THREAT: "threat",
        THREAT_PREVIEW: "threatPreview",
    },
    actions: {
        GET_THREATS: "getThreats",
        GET_THREAT: "getThreat",
        GET_THREAT_PREVIEW: "getThreatPreview",
        CLEAR_THREAT: "clearThreat",
        TRANSITION_THREAT_STATUS: "transitionThreatStatus",
        SAVE_COMMENT: "saveComment",
        DOWNLOAD_HTML: "downloadHtml",
        DELETE_THREAT: "deleteThreat",
        EXPORT_THREAT_LIST: "exportThreatList",
    },
    mutations: {
        SET_THREAT_LOADING: "setThreatLoading",
        SET_THREAT_ERROR: "setThreatError",
        SET_THREATS: "setThreats",
        SET_THREAT: "setThreat",
        SET_THREAT_PREVIEW: "setThreatPreview",
        ADD_COMMENT: "addComment",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const ThreatStoreNamespacedTypes = addNamespace(
    "threat",
    ThreatStoreTypes
);

/**
 * @returns {ThreatStoreState}
 */
export function state() {
    return {
        threatLoading: false,
        threatError: null,
        threats: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        threat: {
            analysis: {},
        },
        preview: {
            url: "",
        },
    };
}

export const getters = {
    [ThreatStoreTypes.getters.THREAT_LOADING]: (state) => () => {
        return state.threatLoading;
    },
    [ThreatStoreTypes.getters.THREAT_ERROR]: (state) => () => {
        return state.threatError;
    },
    [ThreatStoreTypes.getters.THREATS]: (state) => () => {
        return state.threats;
    },
    [ThreatStoreTypes.getters.THREAT]: (state) => () => {
        return state.threat;
    },
    [ThreatStoreTypes.getters.THREAT_PREVIEW]: (state) => () => {
        return state.preview;
    },
};

export const actions = {
    /**
     * Sends the given threat to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} query
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.GET_THREATS]({ commit }, query) {
        commit(ThreatStoreTypes.mutations.SET_THREAT_LOADING, true);
        commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, null);

        return postData("/threats", query)
            .then((response) => {
                commit(ThreatStoreTypes.mutations.SET_THREATS, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, errors);
            })
            .finally(() => {
                commit(ThreatStoreTypes.mutations.SET_THREAT_LOADING, false);
            });
    },

    /**
     * Sends the given threat to the server.
     *
     * @param {VuexCommit} commit
     * @param threat
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.GET_THREAT]({ commit }, threat) {
        commit(ThreatStoreTypes.mutations.SET_THREAT_LOADING, true);
        commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, null);

        return getData("/threats/" + threat)
            .then((response) => {
                commit(ThreatStoreTypes.mutations.SET_THREAT, response.data);
            })
            .catch((errors) => {
                commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, errors);
            })
            .finally(() => {
                commit(ThreatStoreTypes.mutations.SET_THREAT_LOADING, false);
            });
    },

    /**
     * Sends the given threat to the server.
     *
     * @param {VuexCommit} commit
     * @param threat
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.GET_THREAT_PREVIEW]({ commit }, threat) {
        return getData("/threats/" + threat + "/preview").then((response) => {
            commit(
                ThreatStoreTypes.mutations.SET_THREAT_PREVIEW,
                response.data
            );
        });
    },

    /**
     * Clears the threat currently in the store.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.CLEAR_THREAT]({ commit }) {
        commit(ThreatStoreTypes.mutations.SET_THREAT, {});
        commit(ThreatStoreTypes.mutations.SET_THREAT_PREVIEW, {
            url: "",
        });
    },

    /**
     * Marks the given threat as spam.
     *
     * @param {VuexCommit} commit
     * @param {String} id
     * @param {String} status
     * @param {Boolean} notify
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.TRANSITION_THREAT_STATUS](
        { commit },
        { id, status, notify }
    ) {
        commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, null);

        return postData("/threats/" + id + "/transition-status", {
            status: status,
            notify: notify,
        })
            .then((response) => {
                commit(ThreatStoreTypes.mutations.SET_THREAT, response.data);
            })
            .catch((errors) => {
                commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, errors);
            });
    },

    /**
     * Marks the given threat as spam.
     *
     * @param {VuexCommit} commit
     * @param {String} id
     * @param {String} comment
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.SAVE_COMMENT]({ commit }, { id, comment }) {
        commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, null);

        return postData("/threats/" + id + "/comment", { comment: comment })
            .then((response) => {
                commit(ThreatStoreTypes.mutations.SET_THREAT, response.data);
            })
            .catch((errors) => {
                commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, errors);
            });
    },

    /**
     * Downloads the given threat as HTML.
     *
     * @param {VuexCommit} commit
     * @param {String} id
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.DOWNLOAD_HTML]({ commit }, id) {
        commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, null);

        return postData("/threats/" + id + "/download").catch((errors) => {
            commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, errors);
        });
    },

    /**
     * Downloads the given threat as EML.
     *
     * @param {VuexCommit} commit
     * @param {String} id
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.DELETE_THREAT]({ commit }, id) {
        commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, null);

        return postData("/threats/" + id + "/delete").catch((errors) => {
            commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, errors);
        });
    },

    /**
     * Export threats as CSV.
     *
     * @param {VuexCommit} commit
     * @param {String} id
     * @return {Promise}
     */
    [ThreatStoreTypes.actions.EXPORT_THREAT_LIST]({ commit }) {
        commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, null);

        return postData("/threats/export").catch((errors) => {
            commit(ThreatStoreTypes.mutations.SET_THREAT_ERROR, errors);
        });
    },
};

export const mutations = {
    /**
     * Sets the loading state for threat.
     *
     * @param {ThreatStoreState} state
     * @param {boolean} loading
     */
    [ThreatStoreTypes.mutations.SET_THREAT_LOADING](state, loading) {
        state.threatLoading = loading;
    },

    /**
     * Sets a new threat error.
     *
     * @param {ThreatStoreState} state
     * @param {ErrorOrObject} error
     */
    [ThreatStoreTypes.mutations.SET_THREAT_ERROR](state, error) {
        state.threatError = error;
    },

    /**
     * Sets a new threat error.
     *
     * @param {ThreatStoreState} state
     * @param {Object} data
     */
    [ThreatStoreTypes.mutations.SET_THREATS](state, data) {
        state.threats = data;
    },

    /**
     * Sets a new threat error.
     *
     * @param {ThreatStoreState} state
     * @param {Object} data
     */
    [ThreatStoreTypes.mutations.SET_THREAT](state, data) {
        state.threat = data;
    },

    /**
     * Sets a new threat error.
     *
     * @param {ThreatStoreState} state
     * @param {Object} data
     */
    [ThreatStoreTypes.mutations.SET_THREAT_PREVIEW](state, data) {
        state.preview = data;
    },
};

export default {
    namespaced: true,
    Types: ThreatStoreTypes,
    NamespacedTypes: ThreatStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
