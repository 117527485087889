import axios from "axios";
import store from "@/store";
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { extractErrorObject } from "@/utils/errors";
import { redirectTo } from "@/router";

export let axiosInstance = null;
export let publicAxiosInstance = null;

export const initialize = () => {
    if (!axiosInstance) {
        axiosInstance = axios.create({
            withCredentials: true,
            baseURL: `${process.env.VUE_APP_API_ROOT}/api/v1`,
            headers: {
                common: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        });
    }
};

export const initializePublic = () => {
    if (!publicAxiosInstance) {
        publicAxiosInstance = axios.create({
            withCredentials: true,
            baseURL: `${process.env.VUE_APP_API_ROOT}/api/public/v1`,
            headers: {
                common: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        });
    }
};

export const fetchCsrfCookie = () => {
    return getRawData("/auth/csrf");
};

export const getRawData = (path, config) => {
    const rawAxiosInstance = axios.create({
        withCredentials: true,
        baseURL: `${process.env.VUE_APP_API_ROOT}/api/v1`,
    });

    if (config) {
        return rawAxiosInstance.get(path, config);
    } else {
        return rawAxiosInstance.get(path);
    }
};

export const setAuthToken = (token) => {
    initialize();

    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const setWorkspaceToken = (workspace) => {
    initialize();

    axiosInstance.defaults.headers.common["X-Workspace"] = workspace;
};

export const getData = (path, data) => {
    initialize();

    return axiosInstance
        .get(path, {
            params: data,
        })
        .then(extractResponseData)
        .catch(extractErrorObjectAndReject);
};

export const postPublicData = (path, data) => {
    initializePublic();

    return publicAxiosInstance
        .post(path, data)
        .then(extractResponseData)
        .catch(extractErrorObjectAndReject);
};

export const postData = (path, data) => {
    initialize();

    return axiosInstance
        .post(path, data)
        .then(extractResponseData)
        .catch(extractErrorObjectAndReject);
};

export const rejectWithData = (data) => {
    if (
        data.status === 401 ||
        data.status === 403 ||
        data.status === 419 ||
        data.status === 503
    ) {
        store.dispatch(AuthStoreNamespacedTypes.actions.LOGOUT).then(() => {
            store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.CLEAR_SESSION)
                .then(() => {
                    redirectTo("login");
                });
        });

        return;
    }
    return Promise.reject(data);
};

export const extractErrorObjectAndReject = (error) => {
    return rejectWithData(extractErrorObject(error));
};

export const extractResponseData = (response) => {
    if (!response) {
        throw "api.errors.invalid-data-format";
    }

    return response;
};
