/**
 * A workspace store.
 *
 * @typedef {{ workspaceLoading: boolean, workspaceError: ErrorOrObject, lastUpdateTime: string|null, workspace: Object, campaignServices: Array, campaignSelectedServices: Array, fieldsError: ErrorOrObject, videosError: ErrorOrObject }} WorkspaceStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { isRecentEvent } from "@/utils/date";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const WorkspaceStoreTypes = {
    getters: {
        WORKSPACE_LOADING: "workspaceLoading",
        WORKSPACE_ERROR: "workspaceError",
        WORKSPACE: "workspace",
        INTEGRATIONS: "integrations",
        CAMPAIGN_SERVICES: "campaignServices",
        CAMPAIGN_SELECTED_SERVICES: "campaignSelectedServices",
        FIELDS_ERROR: "fieldsError",
        VIDEOS_ERROR: "videosError",
    },
    actions: {
        GET_WORKSPACE: "getWorkspace",
        UPDATE_SETTINGS: "updateSettings",
        INVITE_USER: "inviteUser",
        REVOKE_ACCESS: "revokeAccess",
        UPDATE_CAMPAIGN_SETTINGS: "updateCampaignSettings",
        EXPORT_CAMPAIGN_DOMAINS_LIST: "exportCampaignDomainsList",
        GET_CAMPAIGN_SERVICES: "getCampaignServices",
        UPDATE_CAMPAIGN_SERVICES_SETTINGS: "updateCampaignServicesSettings",
        UPDATE_THREAT_MANAGEMENT_SETTINGS: "updateThreatManagementSettings",
        UPDATE_FIELDS: "updateFields",
        STORE_VIDEO_META: "storeVideoMeta",
        UPLOAD_VIDEO: "uploadVideo",
        UPDATE_ONBOARDED_STATUS: "updateOnboardedStatus",
        CLEAR_SESSION: "clearSession",
        GET_CAMPAIGN_STATISTICS: "getCampaignStatistics",
    },
    mutations: {
        SET_WORKSPACE_LOADING: "setWorkspaceLoading",
        SET_WORKSPACE_ERROR: "setWorkspaceError",
        SET_LAST_UPDATE_TIME: "setLastUpdateTime",
        SET_WORKSPACE: "setWorkspace",
        SUBTRACT_UNHANDLED_THREATS_COUNT: "subtractUnhandledThreatsCount",
        REMOVE_USER: "removeUser",
        SET_CAMPAIGN_SERVICES: "setCampaignServices",
        SET_CAMPAIGN_SELECTED_SERVICES: "setCampaignSelectedServices",
        UPDATE_CAMPAIGN_SELECTED_SERVICES: "updateCampaignSelectedServices",
        UPDATE_CAMPAIGN_SERVICES_COUNT: "updateCampaignServicesCount",
        SET_FIELDS_ERROR: "setFieldsError",
        SET_VIDEOS_ERROR: "setVideosError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const WorkspaceStoreNamespacedTypes = addNamespace(
    "workspace",
    WorkspaceStoreTypes
);

/**
 * @returns {WorkspaceStoreState}
 */
export function state() {
    return {
        workspaceLoading: false,
        workspaceError: null,
        lastUpdateTime: null,
        workspace: {
            id: null,
            name: null,
            hotline: null,
            industry: null,
            campaign_services_count: 0,
            unhandled_threats_count: 0,
            users: [],
            integrations: [],
            domains: [],
            onboarded: false,
            fields: [],
            simulation_settings: {
                sensibility: "click",
                remedial_training: false,
                track_replies: false,
            },
            threat_management_settings: {
                is_enabled: false,
                enable_notifications: false,
                inbound_email_token: null,
                alias_email: null,
                enable_forwarding: false,
                forwarding_email: null,
                enable_third_party_submission: false,
            },
            created_at: null,
        },
        campaignServices: [],
        campaignSelectedServices: [],
        fieldsError: null,
        videosError: null,
    };
}

export const getters = {
    [WorkspaceStoreTypes.getters.WORKSPACE_LOADING]: (state) => () => {
        return state.workspaceLoading;
    },
    [WorkspaceStoreTypes.getters.WORKSPACE_ERROR]: (state) => () => {
        return state.workspaceError;
    },
    [WorkspaceStoreTypes.getters.WORKSPACE]: (state) => () => {
        return state.workspace;
    },
    [WorkspaceStoreTypes.getters.INTEGRATIONS]: (state) => () => {
        return state.workspace.integrations;
    },
    [WorkspaceStoreTypes.getters.CAMPAIGN_SERVICES]: (state) => () => {
        return state.campaignServices;
    },
    [WorkspaceStoreTypes.getters.CAMPAIGN_SELECTED_SERVICES]: (state) => () => {
        return state.campaignSelectedServices;
    },
    [WorkspaceStoreTypes.getters.FIELDS_ERROR]: (state) => () => {
        return state.fieldsError;
    },
    [WorkspaceStoreTypes.getters.VIDEOS_ERROR]: (state) => () => {
        return state.videosError;
    },
};

export const actions = {
    /**
     * Sends the given workspace to the server.
     *
     * @param {WorkspaceStoreState} state
     * @param {VuexCommit} commit
     * @param {boolean} force
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.GET_WORKSPACE]({ state, commit }, { force }) {
        if (
            state.workspace.id !== null &&
            state.lastUpdateTime !== null &&
            isRecentEvent(state.lastUpdateTime) &&
            !force
        ) {
            return Promise.resolve();
        }

        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING, true);
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return getData("/workspace")
            .then((response) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE,
                    response.data
                );
                commit(
                    WorkspaceStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.UPDATE_SETTINGS]({ commit }, form) {
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING, true);
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return postData("/workspace", form)
            .then((response) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE,
                    response.data
                );
                commit(
                    WorkspaceStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING,
                    false
                );
            });
    },

    /**
     * Send a user invite to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.INVITE_USER]({ commit }, form) {
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return postData("/workspace/users/invite", form)
            .then((response) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE,
                    response.data
                );
                commit(
                    WorkspaceStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            });
    },

    /**
     * Revoke a user invite.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.REVOKE_ACCESS]({ commit }, form) {
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return postData("/workspace/users/revoke", form)
            .then(() => {
                commit(WorkspaceStoreTypes.mutations.REMOVE_USER, form);
            })
            .catch((errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.UPDATE_CAMPAIGN_SETTINGS]({ commit }, form) {
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING, true);
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return postData("/workspace/simulations/settings", form)
            .then((response) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE,
                    response.data
                );
                commit(
                    WorkspaceStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING,
                    false
                );
            });
    },

    /**
     * Export a list of domains used in campaigns.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.EXPORT_CAMPAIGN_DOMAINS_LIST]({ commit }) {
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return postData("/workspace/simulations/domains/export").catch(
            (errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            }
        );
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.GET_CAMPAIGN_SERVICES]({ commit }) {
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING, true);
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return getData("/workspace/simulations/services")
            .then((response) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_CAMPAIGN_SERVICES,
                    getProperty(response.data, "services", [])
                );
                commit(
                    WorkspaceStoreTypes.mutations
                        .SET_CAMPAIGN_SELECTED_SERVICES,
                    getProperty(response.data, "selected", [])
                );
            })
            .catch((errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {WorkspaceStoreState} state
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.UPDATE_CAMPAIGN_SERVICES_SETTINGS]({
        state,
        commit,
    }) {
        if (state.campaignSelectedServices.length === 0) {
            return Promise.resolve();
        }

        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return postData("/workspace/simulations/services", {
            services: state.campaignSelectedServices,
        })
            .then(() => {
                commit(
                    WorkspaceStoreTypes.mutations
                        .UPDATE_CAMPAIGN_SERVICES_COUNT,
                    state.campaignSelectedServices.length
                );
            })
            .catch((errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.UPDATE_THREAT_MANAGEMENT_SETTINGS](
        { commit },
        form
    ) {
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING, true);
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR, null);

        return postData("/workspace/threat-management", form)
            .then((response) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE,
                    response.data
                );
                commit(
                    WorkspaceStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.UPDATE_FIELDS]({ commit }, form) {
        commit(WorkspaceStoreTypes.mutations.SET_FIELDS_ERROR, null);

        return postData("/workspace/fields", form).catch((errors) => {
            commit(WorkspaceStoreTypes.mutations.SET_FIELDS_ERROR, errors);
        });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.STORE_VIDEO_META]({ commit }, form) {
        commit(WorkspaceStoreTypes.mutations.SET_VIDEOS_ERROR, null);

        return postData("/workspace/videos", form).catch((errors) => {
            commit(WorkspaceStoreTypes.mutations.SET_VIDEOS_ERROR, errors);
        });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.UPLOAD_VIDEO]({ commit }, { id, form }) {
        commit(WorkspaceStoreTypes.mutations.SET_VIDEOS_ERROR, null);

        return postData("/workspace/videos/" + id, form).catch((errors) => {
            commit(WorkspaceStoreTypes.mutations.SET_VIDEOS_ERROR, errors);
        });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.UPDATE_ONBOARDED_STATUS]({ commit }) {
        return postData("/workspace/onboarded").then((response) => {
            commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE, response.data);
        });
    },

    /**
     * Sends the given workspace to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [WorkspaceStoreTypes.actions.CLEAR_SESSION]({ commit }) {
        commit(WorkspaceStoreTypes.mutations.SET_WORKSPACE, {
            id: null,
            name: null,
            hotline: null,
            industry: null,
            campaign_services_count: 0,
            unhandled_threats_count: 0,
            users: [],
            integrations: [],
            domains: [],
            onboarded: false,
            fields: [],
            simulation_settings: {
                sensibility: "click",
                remedial_training: false,
                track_replies: false,
            },
            threat_management_settings: {
                is_enabled: false,
                enable_notifications: false,
                inbound_email_token: null,
                alias_email: null,
                enable_forwarding: false,
                forwarding_email: null,
                enable_third_party_submission: false,
            },
            created_at: null,
        });
    },
};

export const mutations = {
    /**
     * Sets the loading state for workspace.
     *
     * @param {WorkspaceStoreState} state
     * @param {boolean} loading
     */
    [WorkspaceStoreTypes.mutations.SET_WORKSPACE_LOADING](state, loading) {
        state.workspaceLoading = loading;
    },

    /**
     * Sets a new workspace error.
     *
     * @param {WorkspaceStoreState} state
     * @param {ErrorOrObject} error
     */
    [WorkspaceStoreTypes.mutations.SET_WORKSPACE_ERROR](state, error) {
        state.workspaceError = error;
    },

    /**
     * Sets a new breach error.
     *
     * @param {{lastUpdateTime: Date}} state
     * @param {string} lastUpdateTime
     */
    [WorkspaceStoreTypes.mutations.SET_LAST_UPDATE_TIME](
        state,
        lastUpdateTime
    ) {
        state.lastUpdateTime = new Date(lastUpdateTime);
    },

    /**
     * Sets a new workspace error.
     *
     * @param {WorkspaceStoreState} state
     * @param {Object} data
     */
    [WorkspaceStoreTypes.mutations.SET_WORKSPACE](
        state,
        {
            id = null,
            name = null,
            hotline = null,
            industry = null,
            campaign_services_count = 0,
            unhandled_threats_count = 0,
            users = [],
            integrations = [],
            domains = [],
            onboarded = false,
            fields = [],
            simulation_settings = {
                sensibility: "click",
                remedial_training: false,
                track_replies: false,
            },
            threat_management_settings = {
                is_enabled: false,
                enable_notifications: false,
                inbound_email_token: null,
                alias_email: null,
                enable_forwarding: false,
                forwarding_email: null,
                enable_third_party_submission: false,
            },
            created_at = null,
        }
    ) {
        state.workspace.id = id;
        state.workspace.name = name;
        state.workspace.hotline = hotline;
        state.workspace.industry = industry;
        state.workspace.campaign_services_count = campaign_services_count;
        state.workspace.unhandled_threats_count = unhandled_threats_count;
        state.workspace.users = users;
        state.workspace.integrations = integrations;
        state.workspace.domains = domains;
        state.workspace.onboarded = onboarded;
        state.workspace.fields = fields;
        state.workspace.simulation_settings = simulation_settings;
        state.workspace.threat_management_settings = threat_management_settings;
        state.workspace.created_at = created_at;
    },

    /**
     * Subtracts one from the unhandled threats count.
     *
     * @param {WorkspaceStoreState} state
     */
    [WorkspaceStoreTypes.mutations.SUBTRACT_UNHANDLED_THREATS_COUNT](state) {
        state.workspace.unhandled_threats_count = Math.max(
            0,
            state.workspace.unhandled_threats_count - 1
        );
    },

    /**
     * Removes a user from the workspace.
     *
     * @param {WorkspaceStoreState} state
     * @param {Object} data
     */
    [WorkspaceStoreTypes.mutations.REMOVE_USER](state, data) {
        state.workspace.users = state.workspace.users.filter(
            (user) => user.id !== data.id
        );
    },

    /**
     * Sets the services for the workspace.
     *
     * @param {WorkspaceStoreState} state
     * @param {Object} data
     */
    [WorkspaceStoreTypes.mutations.SET_CAMPAIGN_SERVICES](state, data) {
        state.campaignServices = data;
    },

    /**
     * Sets the services for the workspace.
     *
     * @param {WorkspaceStoreState} state
     * @param {Object} data
     */
    [WorkspaceStoreTypes.mutations.SET_CAMPAIGN_SELECTED_SERVICES](
        state,
        data
    ) {
        state.campaignSelectedServices = data;
    },

    /**
     * Sets the services for the workspace.
     *
     * @param {WorkspaceStoreState} state
     * @param {String} id
     */
    [WorkspaceStoreTypes.mutations.UPDATE_CAMPAIGN_SELECTED_SERVICES](
        state,
        id
    ) {
        state.campaignSelectedServices.indexOf(id) === -1
            ? state.campaignSelectedServices.push(id)
            : state.campaignSelectedServices.splice(
                  state.campaignSelectedServices.indexOf(id),
                  1
              );
    },

    /**
     * Updates the campaign services count.
     */
    [WorkspaceStoreTypes.mutations.UPDATE_CAMPAIGN_SERVICES_COUNT](
        state,
        count
    ) {
        state.workspace.campaign_services_count = count;
    },

    /**
     * Sets fields errors.
     */
    [WorkspaceStoreTypes.mutations.SET_FIELDS_ERROR](state, error) {
        state.fieldsError = error;
    },

    /**
     * Sets video errors.
     */
    [WorkspaceStoreTypes.mutations.SET_VIDEOS_ERROR](state, error) {
        state.videosError = error;
    },
};

export default {
    namespaced: true,
    Types: WorkspaceStoreTypes,
    NamespacedTypes: WorkspaceStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
