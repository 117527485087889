<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        resource="threat.single"
        @update="refresh"
    >
        <template v-slot:name="{ item }">
            <div class="ml-4">
                <div class="text-sm font-medium text-slate-900">
                    {{ item.subject }}
                </div>
                <div class="text-sm text-slate-500">
                    {{ item.from_email }}
                </div>
            </div>
        </template>
        <template v-slot:status="{ item }">
            <StatusBadge :status="item.status" />
        </template>
        <template v-slot:report_count="{ item }">
            {{ item.report_count }}
        </template>
        <template v-slot:created_at="{ item }">
            {{ item.created_at | formatDate }}
        </template>
    </DataTable>
</template>

<script>
import { formatDate } from "@/utils/date";
import { initials } from "@/utils/filters";

import DataTableMixin from "@/components/tables/DataTableMixin";
import StatusBadge from "@/components/display/threat/StatusBadge";

export default {
    name: "ThreatTable",
    components: { StatusBadge },
    mixins: [DataTableMixin],
    data: () => ({
        headers: [
            {
                title: "name",
                key: "name",
                sortable: false,
            },
            {
                title: "status",
                key: "status",
                sortable: true,
            },
            {
                title: "report_count",
                key: "report_count",
                sortable: false,
            },
            {
                title: "created_at",
                key: "created_at",
                sortable: true,
            },
        ],
    }),
    filters: {
        formatDate,
        initials,
    },
};
</script>
