<template>
    <div class="relative z-50" role="dialog" aria-modal="true">
        <div
            class="fixed inset-0 bg-slate-500 bg-opacity-25 transition-opacity"
        ></div>
        <div class="fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20">
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <div
                    v-show="showPrompt"
                    id="command-palette"
                    class="mx-auto max-w-2xl transform divide-y divide-slate-500 divide-opacity-20 overflow-hidden rounded-xl bg-slate-900 shadow-2xl transition-all"
                >
                    <div
                        class="relative mx-4 my-1 flex items-center justify-center"
                    >
                        <Icon
                            class="pointer-events-none h-5 w-5 text-slate-500"
                            :icon="IconEnums.SEARCH"
                        />
                        <input
                            type="text"
                            class="h-12 w-full border-0 bg-transparent pl-4 pr-4 text-white placeholder-slate-500 focus:ring-0 sm:text-sm"
                            :placeholder="$t('employees.search.placeholder')"
                            ref="search"
                            v-model="search"
                            @keyup.up="previousListItem"
                            @keyup.down="nextListItem"
                            @keyup.enter="selectListItem"
                        />
                        <div
                            @click="close"
                            class="flex cursor-pointer items-center rounded bg-slate-500 px-1.5 py-1 text-sm font-semibold text-slate-900 hover:bg-slate-600"
                        >
                            <Icon
                                class="pointer-events-none h-4 w-4"
                                :icon="IconEnums.CLOSE"
                            />
                            <span class="ml-2">ESC</span>
                        </div>
                    </div>
                    <div
                        v-if="hasNoSearchResults"
                        class="px-6 py-14 text-center sm:px-14"
                    >
                        <Icon
                            class="mx-auto h-8 w-8 text-slate-500"
                            :icon="IconEnums.ERROR"
                        />
                        <p class="mt-4 text-sm text-slate-200">
                            {{ $t("employees.search.no_results") }}
                        </p>
                    </div>
                    <ul v-else-if="hasSearchQuery">
                        <EmployeePreview
                            v-for="(employee, index) in filteredList"
                            :class="[
                                index === selectedItemIndex
                                    ? 'bg-slate-800'
                                    : '',
                            ]"
                            :key="employee.id"
                            @click.native="choose(employee)"
                            @mouseenter.native="selectedItemIndex = index"
                            :employee="employee"
                        />
                    </ul>
                    <div v-else class="px-6 py-14 text-center sm:px-14">
                        <Icon
                            class="mx-auto h-8 w-8 text-slate-500"
                            :icon="IconEnums.SEARCH"
                        />
                        <p class="mt-4 text-sm text-slate-200">
                            {{ $t("employees.search.empty") }}
                        </p>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { redirectWithParamsTo } from "@/router";

import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import EmployeePreview from "@/components/search/EmployeePreview";

export default {
    name: "EmployeeSearch",
    components: {
        EmployeePreview,
        Icon,
    },
    data: () => ({
        IconEnums,
        showPrompt: false,
        search: "",
        selectedItemIndex: 0,
    }),
    computed: {
        /**
         * All employees
         * @type {Array}
         */
        employees() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.SEARCH_EMPLOYEES
            ]();
        },

        /**
         * Filtered results
         * @type {Array}
         */
        filteredList() {
            return this.flatten(Object.values(this.employees)).filter(
                (employee) => {
                    if (this.search.includes("@")) {
                        return employee.email.includes(
                            this.search.toLowerCase()
                        );
                    }

                    return employee.full_name
                        .toLowerCase()
                        .includes(this.search);
                }
            );
        },

        /**
         * Search has an active query
         * @type {boolean}
         */
        hasSearchQuery() {
            return this.search.length > 1;
        },

        /**
         * Search query has no results
         * @type {boolean}
         */
        hasNoSearchResults() {
            return this.hasSearchQuery && !this.filteredList.length;
        },
    },
    methods: {
        close() {
            this.search = "";
            this.selectedItemIndex = 0;
            this.$emit("close");
        },
        flatten(array) {
            return !Array.isArray(array)
                ? array
                : [].concat.apply([], array.map(this.flatten));
        },
        choose(item) {
            this.close();
            redirectWithParamsTo({
                name: "employee.single.index",
                params: {
                    id: item.id,
                },
            });
        },
        nextListItem() {
            if (this.selectedItemIndex < this.filteredList.length - 1) {
                this.selectedItemIndex++;
            }
        },
        previousListItem() {
            if (this.selectedItemIndex > 0) {
                this.selectedItemIndex--;
            }
        },
        selectListItem() {
            this.choose(this.filteredList[this.selectedItemIndex]);
        },
        escapeListener(event) {
            if (event.key === "Escape") {
                this.close();
            }
        },
    },
    mounted() {
        this.showPrompt = true;

        this.$store.dispatch(
            TeamStoreNamespacedTypes.actions.GET_EMPLOYEES_FOR_SEARCH
        );

        this.$nextTick(() => this.$refs.search.focus());
    },
    created() {
        window.addEventListener("keydown", this.escapeListener);
    },
    destroyed() {
        window.removeEventListener("keydown", this.escapeListener);
    },
};
</script>
